<style lang="sass" scoped>
.container-login
  // background-color: #f8f9fa
  font-size: 12px

  .title
    font-weight: bold
    font-size: 1.5rem
    color: #555

  .form-control
    font-size: 16px

</style>
<template lang="pug">
div.container-login
  .container
    .row.justify-content-center.align-items-center(style='height: 100vh')
      .col-xl-5.col-lg-6.col-md-7
        h3.title.py-3 새로운 계정 만들기
        .mb-4
        form.form(@submit.prevent='signup()' name='signup' autocomplete='dd')
          .form-group
            label.d-block 이름
              span.required *
            input.form-control(type='text' v-model='form.name' required autofocus)
          .form-group
            label.d-block 이메일 주소
              span.required *
            input.form-control(type='email' v-model='form.email' required)
          .form-group
            label.d-block 비밀번호
              span.required *
            input.form-control(type='password' v-model='form.password' required placeholder='Password')
          .form-group
            label.d-block 비밀번호 확인
              span.required *
            input.form-control(type='password' v-model='form.password2' required placeholder='Password')
          .py-3
          .py-3

          //- .form-group
            label.d-block
              span 비즈니스 이름
              span.required *
            input.form-control(type='text' v-model='form.property_name' required placeholder='사업자명 또는 상호명')
          //- .form-group
            label.d-block 웹사이트 주소 URL
            input.form-control(type='text' v-model='form.url' placeholder='domain.com')
          .form-group
            label.d-block 연락처
            input.form-control(type='text' v-model='form.phone' placeholder='0000-0000')
            .py-2
              b-form-checkbox(v-model='form.agree_phone' value='Y' unchecked-value='N') 이용안내 및 고객지원 전화수신 동의
          .form-group
            label.d-block 가입경로
            textarea.form-control(v-model='form.referer' placeholder='예) 추천인 아이디, 쿠폰코드, 블로그, 카페')
          .form-group
            label.d-block 문의 남기기
            textarea.form-control(v-model='form.memo' rows=5 placeholder='궁금하신 점을 자유롭게 남겨주세요. \n\n초기 데이터 이전이 필요하거나 \n카카오알림톡 발송이 필요한경우\n서비스내 고객센터를 통해 안내드리겠습니다.')
          .form-group
            label.d-block 약관
              span.required *
            //- h3.text-primary d
            //-   span.float-right.text-secondary 프로필 관리
            .py-2
              b-form-checkbox(v-model='form.agree_tos' value='Y' unchecked-value='N' style='padding-left: 2.5rem')
                | 서비스이용약관에 동의합니다.
                a.ml-1(href='https://docs.noitaler.com/terms' target='_blank') 서비스이용약관
            .py-2
              b-form-checkbox(v-model='form.agree_privacy' value='Y' unchecked-value='N' style='padding-left: 2.5rem')
                | 개인정보보호약관에 동의합니다.
                a.float-right.ml-1(href='https://docs.noitaler.com/privacy' target='_blank') 개인정보보호약관

          .mb-3
            .text-right
              b-spinner.ml-2.async(label='회원가입 중...' small variant='secondary' :class='{done:saving}')
              router-link(to='/').btn.btn-default.text-secondary.mr-2 뒤로가기
              button.btn.btn-primary(type='submit' :disabled='saving') 회원가입
              //- pre {{form}}
  modal(name='notice' classes='vm--modal vue-dialog' :width='`375`' :height='`auto`' style='max-width: 375px' :clickToClose='false')
    .vue-dialog-content
      .vue-dialog-content-title 알림
      div
        p 안녕하세요. 지금은 노이텔러 가입을 순차적으로 진행하고 있습니다. 따라서 초대링크를 통해서만 가입을 받고 있으며, 오른쪽 하단에 문의를 주시거나 waiting list에 등록해주시면 빠른 시일내에 연락드리겠습니다. 관심 가져 주셔서 감사합니다.
        br
        | 스튜디오인투 드림

        .py-3
          router-link.btn.btn-light.text-primary.btn-block(to='/') noitaler.com 으로 이동

</template>

<script>

export default {
  name: 'index',
  components: {

  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
  },
  mounted() {
    this.track('화면_진입')
    // this.$store.dispatch('session')
    // this.load()
    const inv = this.$route.query.invitation
    if (inv) {
      this.form.invitation = inv
    } else {
      this.$modal.show('notice')
    }

  },
  data() {
    return {
      // done: true,
      has_invitation: false,
      saving: false,

      form: {
        // name: 'test+0530@test.com',
        // email: 'test+0530@test.com',
        // password: 'test+0530@test.com',
        // password2: 'test+0530@test.com',
        // // phone: '',
        // // agree_phone: 'N',
        // // referer: '',
        // agree_tos: 'Y',
        // agree_privacy: 'Y',
        // invitation: '',


        name: '',
        email: '',
        password: '',
        password2: '',
        phone: '',
        agree_phone: 'N',
        referer: '',
        agree_tos: 'N',
        agree_privacy: 'N',
        invitation: '',
      },
    }
  },
  methods: {
    track(name, prop) {
      window.amplitude?.getInstance().logEvent(name, Object.assign({view:"로그인", subview:"회원가입"}, prop))
    },
    async signup() {
      this.saving = true
      try {
        if (this.form.agree_tos != 'Y') throw new Error('서비스이용약관 동의가 필요합니다.')
        if (this.form.agree_privacy != 'Y') throw new Error('개인정보보호약관 동의가 필요합니다.')

        const r = await this.$http.post('/v1/signup/email', this.form)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '회원가입 실패. 다시 시도해주세요.')
        this.$modal.show('dialog', {
          title: '알림',
          text: '새로운 이메일 계정을 만들었습니다.',
        })
        this.login()
        // this.$router.push({
        //   name: 'property.new'
        // })

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
        this.saving = false
      }
    },
    async login() {
      try {
        const r = await this.$http.post('/v1/login/email', {
          email: this.form.email,
          password: this.form.password,
        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '로그인 실패. 이메일 주소와 비밀번호를 확인해주세요.')

        const token = r.data.token

        window.localStorage.studio_token = token

        // await this.$store.dispatch('session')
        this.$router.push({
          name: 'property.new'
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    // async login() {
    //   try {
    //     const r = await this.$http.post('/v1/login/email', {
    //       ...this.form,
    //     })
    //     if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '로그인 실패')

    //     const token = r.data.token

    //     window.localStorage.studio_token = token

    //     await this.$store.dispatch('session')

    //     this.$router.push({
    //       path: '/apps',
    //       query: {
    //         choose: 'Y',
    //       }
    //     })

    //   } catch (error) {
    //     alert(error.message)
    //   }
    // },
  },
}
</script>
